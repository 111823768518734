@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  composes: baseColumn;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 64px;
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.searchContainer {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
