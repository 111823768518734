@import '../../../../styles/customMediaQueries.css';

.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchCss {
  margin: 31px 0 20px 0;
  width: 100%;

  @media (--viewportMedium) {
    width: 706px;
    margin: 27px auto 27px auto;
  }
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

.textTitleCenter {
  text-align: center;
  width: 70%;
  text-wrap: wrap;
  margin: 0 auto;

  font-size: 44px;
  letter-spacing: -1.5px;
  line-height: 48px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);

  @media (--viewportMedium) {
    /* width: 706px; */
    max-width: 706px;
    min-height: 53px;
  }
}

.textDescriptionCenter {
  text-align: center;
  width: 70%;
  color: #ffffff !important;
  opacity: 100% !important;
  margin: 12px auto 0 auto;
  text-wrap: wrap;
  font-size: 26px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -1px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);
}

.linkPosition {
  /* margin-top: 10px; */
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.305px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);
  /* margin-bottom: 91px; */

  @media (--viewportMedium) {
    /* margin-bottom: 167px; */
  }
}

.searchContainer {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
