@import '../../../../styles/customMediaQueries.css';
.root {
  /* min-height: 372px; */
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.mobileHelp {
  color: var(--colorGrey300);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

.linkPosition {
  /* margin-top: 10px; */
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.305px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);
  /* margin-bottom: 50px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  } */
}

.textTitleCenter {
  text-align: center;

  width: 299px;
  margin: auto;
  height: 162px;
  text-wrap: initial;

  font-size: 36px;
  line-height: 1.1; /* 116.667% */

  max-width: 700px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);

  margin: auto;

  @media (min-width: 480px) {
    width: auto;
  }

  @media (--viewportMedium) {
    font-size: 44px;
    line-height: 48px; /* 109.091% */
    width: 706px;
    height: 116px;
    /* margin: 50px auto 0px auto; */
    padding-bottom: 12px;
  }
}

.textDescriptionCenter {
  text-align: center;

  width: 198px;
  margin: auto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  letter-spacing: -0.305px;
  text-shadow: 2px 2px 8px hsl(0deg 0% 0%/25%);
}

.searchCss {
  margin: 31px auto 20px auto;
  width: 325px;

  @media (min-width: 480px) {
    width: auto;
    margin: 27px 15px 27px 15px;
  }

  @media (--viewportMedium) {
    width: 706px;
    margin: 27px auto 27px auto;
  }
}

.customSearch {
  padding: 32px 0;
  position: relative;
}
